@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");

:root {
	--primary-color: #5797ee;
	--secondry-color: #F9A07B;
	--third-color: #3FC08C;
	--danger-color: #dc3545;
	--success-color: #146c43;
}


* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}


::selection {
	background-color: var(--primary-color);
	color: #fff;
}



body {
	margin: 0;
	padding: 0;
	font-size: 15px;
	color: #111;
	line-height: 1.6;
	font-family: Roboto Condensed, Microsoft JhengHei, fantasy, Arial, Helvetica, sans-serif;
	background-color: #f2f2f2;

}



a:link {
	color: #333333;
}

a:visited {
	color: #333333;
}

a:hover,
a:active {
	color: #1B74E4;
}

/* Header */

#container {
	width: 98%;
	max-width: 1660px;
	margin-top: 0px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 0px;
}

#header {
	width: 100%;
	height: 145px;
	padding-bottom: 10px;
}

#top {
	display: flex;
}

#logo {
	height: 100px;
	width: 350px;
	color: #202A43;
	line-height: 1.3;
}

#logo a {
	text-decoration: none;
}

#logo-text01 {
	width: 100%;
	text-align: center;
	padding-top: 30px;
	letter-spacing: 1px;
	font-size: 17px;
}

#logo-text02 {
	width: 100%;
	font-size: 25px;
	font-weight: bolder;
	text-align: center;
	padding-top: 0px;
	padding-bottom: 10px;
	letter-spacing: 1px;
}

#banner {
	width: calc(100% - 350px);
	height: 100px;
	background-image: url(../images/top.png);
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: contain;
}

#menu {
	width: 100%;
	display: block;
	height: 45px;
	background-color: var(--primary-color);
	border-radius: 10px;
	box-shadow: 0 1px 20px 0 rgb(0 0 0 / 10%);
	margin-bottom: 20px;
}

#menu ul {
	width: 100%;
	max-width: 1000px;
	margin: 0px;
	padding: 0px 50px;
	list-style-type: none;
	display: flex;
}

#menu ul li {
	margin: 0px;
	list-style-type: none;
	flex: 1;
	padding: 11px 15px;
}

#menu ul li a {
	color: #FFFFFF;
	text-decoration: none;
	padding: 7px 15px;
}

#menu ul li a:hover {}

#menu ul li a.current {
	color: #fff;
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 15px;
}

#search {
	height: 37px;
	width: 270px;
	border-top-style: none;
	border-right-style: none;
	border-bottom-style: none;
	border-left-style: none;
	border-top-width: 0px;
	border-right-width: 0px;
	border-bottom-width: 0px;
	border-left-width: 0px;
}

#search input {
	height: 25px;
	border: 0px solid #FFFFFF;
	background-image: url(../images/search-bg.jpg);
	background-repeat: repeat;
	background-position: center center;
	width: 170px;
	padding-top: 10px;
	padding-left: 20px;
	padding-bottom: 2px;
	color: #4073C6;
	font-family: Arial, Helvetica, sans-serif;
}

/* content */

#master {}

#master-top {}

#master-main {
	width: 100%;
	margin-top: 20px;
}

#master-main-bg {
	width: 100%;
	display: flex;
	min-height: 57vh;
}

#master-bottom {}

#left {
	width: 21%;
	margin-right: 1%;
	border-radius: 10px;
	background: linear-gradient(to right, rgb(250, 250, 250) -30%, #ffffff 100%);
	box-shadow: -4px -4px 10px 0 rgb(255 255 255 / 83%), 4px 4px 10px 0 rgb(208 208 208 / 70%), inset 2px 2px 3px #fff;
	border: 1px solid rgba(255, 255, 255, 0.2);
	padding: 2%;
	background-image: url(../images/left-pic.png);
	background-repeat: no-repeat;
	background-position: left bottom;
	background-size: contain;
}

#submenu-title {
	font-size: 1.3rem;
	line-height: 1;
}

#submenu-title span {
	display: inline-block;
	color: #ffffff;
	margin: 1px;
	padding: 5px;
	background-color: var(--secondry-color);
	border-radius: 5px;
}

#submenu {
	text-align: left;
	margin: 0px;
	padding: 10px 0 200px 0;
}

#submenu ul {
	list-style-type: none;
	width: 100%;
	margin: 0px;
	padding: 0px;
}

#submenu ul li {
	width: 100%;
	margin: 0px;
	list-style-type: none;
	padding: 7px 0;
	border-bottom: 1px solid #999;
	font-size: 17px;
	font-weight: 700;
}

#submenu ul li a {
	text-decoration: none;
	padding: 6px 0px;
	color: #333333;
	width: 100%;
	display: block;
}

#submenu ul li a:hover {}

#submenu ul li a.current {
	font-weight: 700;
	color: var(--secondry-color);
}

#submenu ul li ul {
	padding: 5px 5px 5px 15px;
	font-weight: normal;
	border-left: 1px solid #999;
}

#submenu ul li ul li {
	padding-top: 0px;
	padding-bottom: 0px;
	border-bottom: 0px solid #999;
	font-size: 15px;
	font-weight: 500;
}

#submenu ul li ul li a {
	border-top-style: none;
	border-right-style: none;
	border-bottom-style: none;
	border-left-style: none;
	padding-top: 0px;
	padding-right: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
}

#submenu ul li ul li a:hover,
#submenu ul li ul li a.current {}

#content {
	width: 77%;
	border-radius: 10px;
	background: linear-gradient(to right, rgb(250, 250, 250) -30%, #ffffff 100%);
	box-shadow: -4px -4px 10px 0 rgb(255 255 255 / 83%), 4px 4px 10px 0 rgb(208 208 208 / 70%), inset 2px 2px 3px #fff;
	border: 1px solid rgba(255, 255, 255, 0.2);
	padding: 2%;
}

#main {}

h1 {
	font-size: 24px;
	margin: 0px;
	width: 100%;
	color: #333;
	padding-top: 5px;
	padding-right: 0px;
	padding-bottom: 15px;
	padding-left: 0px;
}

h3 {
	font-size: 18px;
	color: #000000;
	padding-top: 0px;
	padding-right: 0px;
	padding-bottom: 5px;
	padding-left: 0px;
	margin-top: 0px;
	margin-right: 30px;
	margin-bottom: 0px;
	margin-left: 0px;
	letter-spacing: 1px;
}

h4 {
	font-size: 15px;
	margin: 0px;
	padding: 0px;
}

#main div {
	padding-top: 5px;
	padding-bottom: 5px;
}

#main-menu {
	width: 100%;
	height: 20px;
	letter-spacing: 1px;
	margin: 0px;
	padding-top: 0px;
	padding-right: 0px;
	padding-bottom: 5px;
	padding-left: 0px;
}

#main-menu ul {
	list-style: none;
	margin: 0px;
	padding: 0px;
	height: 21px;
	width: 715px;
	border-left-width: 1px;
	border-left-style: solid;
	border-left-color: #4073C6;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-color: #4073C6;
}

#main-menu ul li {
	list-style-type: none;
	text-align: center;
	margin: 0px;
	padding: 0px;
	width: auto;
}

#main-menu ul li a:link,
#main-menu ul li a:visited {
	color: #0066CC;
	text-decoration: none;
	height: 15px;
	width: auto;
	padding-top: 5px;
	background-color: #ECF0F3;
	border-top-width: 1px;
	border-right-width: 1px;
	border-top-style: solid;
	border-right-style: solid;
	border-top-color: #4073C6;
	border-right-color: #4073C6;
	padding-right: 20px;
	padding-left: 20px;
}

#main-menu ul li a:hover {
	text-decoration: none;
	font-weight: bold;
	background-color: #FFFFFF;
}

#main-menu ul li a.current {
	text-decoration: none;
	font-weight: bold;
	background-color: #FFFFFF;
	border-top-width: 1px;
	border-right-width: 1px;
	border-bottom-width: 1px;
	border-top-style: solid;
	border-right-style: solid;
	border-bottom-style: solid;
	border-top-color: #4073C6;
	border-right-color: #4073C6;
	border-bottom-color: #FFFFFF;
}

#page {
	TEXT-ALIGN: center;
	width: 100%;
	letter-spacing: 1px;
	margin: 0px;
	padding-top: 10px;
	font-weight: bolder;
	padding-bottom: 10px;
}

#page a {
	PADDING-RIGHT: 3px;
	PADDING-LEFT: 3px;
	PADDING-BOTTOM: 3px;
	PADDING-TOP: 2px;
	TEXT-DECORATION: none;
	letter-spacing: 1px;
	font-weight: bold;
}

#page a:hover {
	COLOR: #0B9F9F;
}

#page a:active {}

#page span.current {
	PADDING-RIGHT: 5px;
	PADDING-LEFT: 5px;
	FONT-WEIGHT: bold;
	PADDING-BOTTOM: 2px;
	COLOR: var(--secondry-color);
	MARGIN-RIGHT: 2px;
	PADDING-TOP: 2px;
	font-size: 16px;
}

#page-focus {
	width: 120px;
	font-weight: bold;
	letter-spacing: 2px;
	height: 20px;
	padding-top: 10px;
}

.left-icon {
	margin-top: -12px;
	margin-right: 8px;
}

.color-1 {
	color: var(--secondry-color);
	font-weight: bold;
}

.color-2 {
	color: #DA8025;
}

.color-3 {
	color: #FF3300;
}

.font-15 {
	font-size: 15px;
}

.font-18 {
	font-size: 18px;
}

/* signin */

#login {
	height: 300px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-top: 120px;
}

#signin {
	background-image: url(../images/signin-bg.jpg);
	background-repeat: no-repeat;
	padding-left: 220px;
	padding-top: 20px;
	padding-bottom: 10px;
	color: #1C2835;
	width: 380px;
	height: 190px;
}

#signin h3 {
	font-size: 30px;
	color: #2D455F;
	font-family: Georgia, "Times New Roman", Times, serif;
	font-weight: normal;
}

#signin div {
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
}

#signin input {
	border: 1px solid #FFFFFF;
	font-weight: bold;
	color: #003333;
}

.leftimg {
	float: left;
	border: none;
	margin-right: 30px;
}

.rightimg {
	float: right;
	border: none;
	clip: rect(auto, auto, auto, auto);
}

.ps {
	border-bottom-width: 1px;
	border-bottom-style: dashed;
	border-bottom-color: var(--secondry-color);
	color: #D56F51;
}

.datatable {
	width: 100% !important;
	border-collapse: collapse;
	border-top: 2px solid var(--secondry-color);
	margin: 10px auto;
}
.fileTH {
	margin-bottom:0px;
}
.fileTd {
	border-top: 0px;
	margin: 0px;
	border-collapse: separate;
}

.datatable th {
	padding: 5px 10px;
	background: var(--secondry-color);
	color: #ffffff;
	text-align: left;
	min-width: 70px;
	border: 1px solid var(--secondry-color);
}

.datatable td {
	padding: 10px 10px;
	border: 1px solid #ccc;
	background-color: #EEF1F5;
}

.datatable tbody tr {}

/*.datatable tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}*/

.datatable tbody tr:last-of-type {
	border-bottom: 2px solid var(--secondry-color);
}

.datatable tbody tr.active-row {
	font-weight: bold;
	color: #009879;
}

legend {
	color: #FFFFFF;
	background-color: var(--secondry-color);
	letter-spacing: 1px;
	padding: 3px 10px;
	border-radius: 5px;
	font-size: 1rem;
}

fieldset {
	min-width: 100% !important;
	border: 1px solid var(--secondry-color);
	margin-top: 10px;
	margin-bottom: 20px;
	padding: 3%;
	border-radius: 5px;
	background-color: #eef1f5;
	;
}

#status {
	height: 25px;
	width: 100%;
	margin-top: -10px;
	text-align: right;
	font-weight: bold;
}

fieldset table {
	min-width: 100% !important;
}

/* FAQ */

.faqs {
	padding: 5px;
}

.faqs .hover {
	cursor: pointer;
	color: #227a4e;
}

.faqs ol {
	counter-reset: myCounter;
	margin-left: 0;
	padding-left: 5px;
	color: rgb(100, 100, 100);
	border-bottom: 1px solid var(--secondry-color);
	font-size: 15px;
}

.faqs li {
	position: relative;
	padding: 10px 45px;
	list-style: none;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	font-weight: bold;
	color: #333;
	border-top: 1px solid var(--secondry-color);
	font-size: 16px !important;
}

.faqs li:before {
	content: counter(myCounter);
	counter-increment: myCounter;
	position: absolute;
	top: 9px;
	left: 8px;
	width: 18px;
	height: 18px;
	line-height: 1.2em;
	padding: 3px;
	color: #fff;
	background-color: var(--secondry-color);
	text-align: center;
	border-radius: 500px;
	z-index: 1;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	font-size: 16px;
}

.faqs ol div {
	margin: 10px 20px;
	color: #000;
	padding: 20px;
	border-left: 3px solid var(--secondry-color);
	background-color: #f1f1f1;
}

.signup-list {
	width: 100%;
	display: block;
	padding: 5%;
	display: flex;
	margin-bottom: 50px;
	border-radius: 10px;
	box-shadow: -4px -4px 10px 0 rgb(255 255 255 / 60%), 4px 4px 10px 0 rgb(208 208 208 / 40%), inset 2px 2px 3px #fff;
}

.signup-list h3 {
	font-size: 24px;
	font-weight: 500;
}

.signup-list-img {
	width: 25%;
	padding: 20px;
}

.signup-list-text {
	width: 75%;
	padding: 50px 20px;
	display: block;
}

/* form ---------------------------------- */



input,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="number"],
textarea,
select {
	outline: none;
	font-size: 15px;
	max-width: 100%;
	box-sizing: border-box;
	background-color: #fff;
	border: 1px solid #ccc;
	font-weight: 500;
	opacity: 1;
	/*border-radius: 999em;*/
	border-radius: 0.5em;
	margin: 3px;
	padding: 7px 7px;
	/* cursor: pointer; */

	/*清除預設選擇框樣式*/
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;

	/*清除選中時樣式*/
	outline: 0px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	transition: .5s;
	resize: none;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1) inset !important;
}



input:focus {
	border: 1px solid var(--secondry-color);
	transition: .5s;
}








/*按鈕*/
input[type="button"],
input[type="submit"],
input[type="reset"] {
	display: inline-block;
	*display: inline;
	padding: 6px 15px;
	margin-bottom: 0;
	*margin-left: .3em;
	font-size: 13px;
	line-height: 20px;
	color: #3d3d3d;
	font-weight: bold;
	text-align: center;
	text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
	vertical-align: middle;
	cursor: pointer;
	background-color: #f1f1f1;
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e3e3e3));
	background-image: -webkit-linear-gradient(top, #ffffff, #e3e3e3);
	background-image: -o-linear-gradient(top, #ffffff, #e3e3e3);
	background-image: linear-gradient(to bottom, #ffffff, #e3e3e3);
	background-image: -moz-linear-gradient(top, #ffffff, #e3e3e3);
	background-repeat: repeat-x;
	border: 1px none #aeaeae;
	border-color: #d2d2d2;
	border-color: rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe3e3e3', GradientType=0);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	*zoom: 1;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0) inset !important;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2) !important;
	transition: .2s;

}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0) !important;
	margin-bottom: -5px;

}



/*end 按鈕*/



input[type="radio"] {
	outline: 0;
	vertical-align: middle;
	-webkit-appearance: none;
	display: inline-block;
	position: relative;
	border-radius: 100px;
	cursor: pointer;
	width: 18px !important;
	height: 18px !important;
	border: 1px solid #C1C1C1;
	margin-right: 7px;
	background-color: #FFFFFF;
	margin-top: 2px;
	margin-bottom: 2px;
	transition: transform 0.2s linear, box-shadow 0.2s linear;
}

input[type="radio"]:checked {
	border: 1px solid var(--third-color);
	background-color: #fff;
	box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
	transform: scale(1.2);
}

input[type="radio"]:checked:after {
	content: '';
	position: absolute;
	top: 3px;
	left: 3px;
	background-color: var(--third-color);
	width: 10px;
	height: 10px;
	border-radius: 100px;
}

input[type="checkbox"] {
	outline: 0;
	vertical-align: middle;
	-webkit-appearance: none;
	display: inline-block;
	position: relative;
	border-radius: 4px;
	cursor: pointer;
	width: 20px !important;
	height: 20px !important;
	border: 2px solid #ddd;
	margin: 0 5px;
	background-color: #FFFFFF;
	margin-top: 4px;
	margin-bottom: 4px;
	transition: transform 0.2s linear, box-shadow 0.2s linear;
}

input[type="checkbox"]:checked {
	border: 1px solid #fff;
	background-color: var(--third-color);
	box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
	transform: scale(1.2);
}

input[type="checkbox"]:checked:after {
	content: '\2714';
	font-size: 16px;
	position: absolute;
	top: -3px;
	left: 3px;
	color: #fff;
}

input[type="radio"]+label,
input[type="checkbox"]+label {
	cursor: pointer;
}

/* footer */

#footer {
	width: 100%;
	display: block;
	text-align: center;
	line-height: 1.5;
	padding-top: 15px;
	padding-bottom: 25px;
	font-size: 13px;
}

.card-box {
	display: flex;
	margin-bottom: 15px;
	border-bottom: 1px solid #ccc;
}

.card {
	flex: 1;
	border-radius: 0px;
	padding: 15px 0 5px 15px;
	text-align: left;
	margin-right: 15px;
	border-right: 1px solid #ccc;
	margin-bottom: 15px;
}

.card:last-child {
	border-right: 0px solid #ccc;
}

/* news */

.news-section {
	margin-top: 20px;
	width: 100%;
	padding: 1.5%;
	display: flex;
	border-radius: 10px;
	box-shadow: -4px -4px 10px 0 rgb(255 255 255 / 83%), 4px 4px 10px 0 rgb(208 208 208 / 70%), inset 2px 2px 3px #fff;
	border: 1px solid rgba(255, 255, 255, 0.2);

}

.news-box {
	flex: 1;
	width: 48%;
	padding: 1.2%;
	margin: 30px;
	border-radius: 10px;
	background: linear-gradient(to right, rgb(250, 250, 250) -30%, #ffffff 100%);
	position: relative;

}


.news-box-scrol {
	max-height: 550px;
	overflow-y: auto;
}

.news-box h1 {
	text-align: center;

}

.news-box img {
	width: 100px;
	position: absolute;
	top: -35px;
	left: -35px;
	display: block
}


/* signup */

.signup-section {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	width: 100%;
	padding: 40px;
	border-radius: 10px;
	box-shadow: -4px -4px 10px 0 rgb(255 255 255 / 83%), 4px 4px 10px 0 rgb(208 208 208 / 70%), inset 2px 2px 3px #fff;
	border: 1px solid rgba(255, 255, 255, 0.2);
}

.signup-section a {
	text-decoration: none !important;
}




.signup-box {
	flex: 1;
	width: 280px;
	padding: 15px;
	margin: 30px;
	border-radius: 25px;
	text-align: center;
	background: linear-gradient(to bottom, rgb(250, 250, 250) -30%, #ddd 100%);
	box-shadow: -4px -4px 10px 0 rgb(255 255 255 / 83%), 4px 4px 10px 0 rgb(208 208 208 / 70%), inset 2px 2px 3px #fff;
	border: 1px solid rgba(255, 255, 255, 0.2);

}

.signup-box img {
	width: 70%;
	padding: 20px;
}

.signup-box:hover {
	background: linear-gradient(to right, rgb(250, 250, 250) -30%, #ffffff 100%);
	box-shadow: -4px -4px 10px 0 rgb(255 255 255 / 0%), 4px 4px 10px 0 rgb(208 208 208 / 0%), inset 2px 2px 3px #fff;
}






/* table */

table {
	max-width: 100%;
	background-color: transparent;
	border-collapse: collapse;
	border-spacing: 0;
}

.table {
	width: 100%;
	margin-bottom: 20px;
}

.table th,
.table td {
	padding: 6px;
	background-color: #dcdcdc;
	text-align: left;
	vertical-align: top;
	border-top: 1px solid #c3c3c3;
}

.table th {
	font-weight: bold;
}

.table thead th {
	background-color: #dcdcdc;
	vertical-align: bottom;
}

.table caption+thead tr:first-child th,
.table caption+thead tr:first-child td,
.table colgroup+thead tr:first-child th,
.table colgroup+thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child tr:first-child td {
	border-top: 0;
}

.table tbody+tbody {
	border-top: 2px solid #c3c3c3;
}

.table .table {
	background-color: #ffffff;
}

.table-condensed th,
.table-condensed td {
	padding: 4px 5px;
}

.table-bordered {
	border: 1px solid #c3c3c3;
	border-collapse: separate;
	*border-collapse: collapse;
	border-left: 0;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}

.table-bordered th,
.table-bordered td {
	background-color: #ffffff;
	border-left: 1px solid #c3c3c3;
}

.table-bordered caption+thead tr:first-child th,
.table-bordered caption+tbody tr:first-child th,
.table-bordered caption+tbody tr:first-child td,
.table-bordered colgroup+thead tr:first-child th,
.table-bordered colgroup+tbody tr:first-child th,
.table-bordered colgroup+tbody tr:first-child td,
.table-bordered thead:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child td {
	border-top: 0;
}

.table-bordered thead:first-child tr:first-child>th:first-child,
.table-bordered tbody:first-child tr:first-child>td:first-child {
	-webkit-border-top-left-radius: 4px;
	border-top-left-radius: 4px;
	-moz-border-radius-topleft: 4px;
}

.table-bordered thead:first-child tr:first-child>th:last-child,
.table-bordered tbody:first-child tr:first-child>td:last-child {
	-webkit-border-top-right-radius: 4px;
	border-top-right-radius: 4px;
	-moz-border-radius-topright: 4px;
}

.table-bordered thead:last-child tr:last-child>th:first-child,
.table-bordered tbody:last-child tr:last-child>td:first-child,
.table-bordered tfoot:last-child tr:last-child>td:first-child {
	-webkit-border-bottom-left-radius: 4px;
	border-bottom-left-radius: 4px;
	-moz-border-radius-bottomleft: 4px;
}

.table-bordered thead:last-child tr:last-child>th:last-child,
.table-bordered tbody:last-child tr:last-child>td:last-child,
.table-bordered tfoot:last-child tr:last-child>td:last-child {
	-webkit-border-bottom-right-radius: 4px;
	border-bottom-right-radius: 4px;
	-moz-border-radius-bottomright: 4px;
}

.table-bordered tfoot+tbody:last-child tr:last-child td:first-child {
	-webkit-border-bottom-left-radius: 0;
	border-bottom-left-radius: 0;
	-moz-border-radius-bottomleft: 0;
}

.table-bordered tfoot+tbody:last-child tr:last-child td:last-child {
	-webkit-border-bottom-right-radius: 0;
	border-bottom-right-radius: 0;
	-moz-border-radius-bottomright: 0;
}

.table-bordered caption+thead tr:first-child th:first-child,
.table-bordered caption+tbody tr:first-child td:first-child,
.table-bordered colgroup+thead tr:first-child th:first-child,
.table-bordered colgroup+tbody tr:first-child td:first-child {
	-webkit-border-top-left-radius: 4px;
	border-top-left-radius: 4px;
	-moz-border-radius-topleft: 4px;
}

.table-bordered caption+thead tr:first-child th:last-child,
.table-bordered caption+tbody tr:first-child td:last-child,
.table-bordered colgroup+thead tr:first-child th:last-child,
.table-bordered colgroup+tbody tr:first-child td:last-child {
	-webkit-border-top-right-radius: 4px;
	border-top-right-radius: 4px;
	-moz-border-radius-topright: 4px;
}

.table-striped tbody>tr:nth-child(odd)>td,
.table-striped tbody>tr:nth-child(odd)>th {
	background-color: #f5f5f5;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
	background-color: #e1e1e1;
}


ul.ULinnerNum {
	list-style: none;
	counter-reset: my-counter;
	padding-left:0.75em
}
ul.ULinnerNum li {
	counter-increment: my-counter;
	text-indent: -1em;
}
ul.ULinnerNum li::before {
	content: "("counter(my-counter) ")";
}

.formNews {
	border-radius: 8px;
	overflow: hidden;
	letter-spacing: 1px
}

	.formNews tr th, .formNews tr td {
		vertical-align: middle;
		padding: 0.5rem;
	}

	.formNews tr td {
		background: #fff;
		border-bottom: 1px solid #c3c3c3;
	}

	.formNews tr th {
		background: var(--secondry-color);
		color: #fff
	}

	.formNews tbody tr:nth-of-type(2n+1) td {
		background: #f2f2f2;
		border-bottom: 1px solid #c3c3c3;
		vertical-align: middle;
	}

.text-right {
	text-align: right;
	justify-content: flex-end;
}

	.text-right input {
		text-align: right;
	}

.text-center {
	text-align: center;
	justify-content: center;
}

	.text-center input {
		text-align: center;
	}

.text-left {
	text-align: left;
	justify-content: flex-end;
}

	.text-left input {
		text-align: left;
	}


input.error,
textarea.error,
select.error {
	border-color: #0007ef;
	border-width: 2px;
}

	input.error::placeholder,
	textarea.error::placeholder,
	select.error::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: #0007ef;
		opacity: 1;
		/* Firefox */
	}

	input.error:-ms-input-placeholder,
	textarea.error:-ms-input-placeholder,
	select.error:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #0007ef;
	}

	input.error::-ms-input-placeholder,
	textarea.error::-ms-input-placeholder,
	select.error::-ms-input-placeholder {
		/* Microsoft Edge */
		color: #0007ef;
	}



/* modal */

.modal-container {
	background-color: #FAFAFA;
	border-radius: 16px;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
	padding: 3.33vw 4.3vw;
	width: clamp(500px, 50%, 680px);
	transition: all .3s ease;
	opacity: 0;
	transform-style: preserve-3d;
	transform: translateY(-80px) perspective(1000px) rotateX(30deg);
}

@media (min-width: 1380px) {
	.modal-container {
		padding: 48px 4.3vw;
	}
}

.modal-container .icon-holder {
	width: 20%;
	position: relative;
	margin: 0 auto;
}

	.modal-container .icon-holder:before {
		content: "";
		display: block;
		width: 100%;
		padding-bottom: 100%;
	}

	.modal-container .icon-holder .icon {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
	}

		.modal-container .icon-holder .icon:before {
			width: 100%;
			height: 100%;
		}



.has-modal {
	overflow: hidden;
}

.modal {
	width: 100%;
	height: 100vh;
	position: fixed;
	z-index: 20;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(29, 67, 111, 0.6);
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	pointer-events: none;
	opacity: 0;
	visibility: hidden;
	transition: all .4s ease;
	transition-property: opacity;
	overflow-y: auto;
}

	.modal .close {
		position: absolute;
		right: 10px;
		top: 10px;
		width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		cursor: pointer;
	}

		.modal .close .icon {
			width: 20px;
			height: 20px;
			pointer-events: none;
		}

			.modal .close .icon:before {
				background-color: #BDBDBD;
				width: 100%;
				height: 100%;
			}

@media (hover: hover) {
	.modal .close .icon:before {
		transition: all .3s ease;
		transition-property: background-color, transform;
	}

	.modal .close:hover .icon:before {
		background-color: #1D436F;
		transform: scale(1.125);
	}
}

.modal.small.active {
	opacity: 1;
	visibility: visible;
	pointer-events: auto;
}

	.modal.small.active .modal-container {
		transform: none;
		opacity: 1;
		transition: all .5s ease;
	}

.modal.small .form-item {
	margin: 0 0 20px;
}

	.modal.small .form-item .value > div {
		margin-bottom: 10px;
	}

.modal.large {
	padding-bottom: 10vh;
}

	.modal.large .modal-container {
		width: clamp(880px, 70%, 1280px);
		margin-top: 10vh;
		margin-bottom: auto;
	}

.modal.active {
	opacity: 1;
	visibility: visible;
	pointer-events: auto;
}

	.modal.active .modal-container {
		transform: none;
		opacity: 1;
		transition: all .5s ease;
	}

.modal.layer-2 {
	z-index: 40;
}

.modal.layer-3 {
	z-index: 60;
}

.modal.layer-4 {
	z-index: 80;
}

.modal.layer-5 {
	z-index: 80;
}

.modal-stamp {
	position: absolute;
	width: 60px;
	height: 60px;
	font-size: 1rem;
	font-weight: 500;
	border: 1px solid #F37402;
	border-radius: 50%;
	padding: 4px;
	color: #F37402;
	right: 105px;
	top: 40px;
}

	.modal-stamp .circle {
		border: 4px solid #F37402;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		transform: rotate(9deg);
	}

@media (min-width: 1180px) {
	.modal-stamp {
		font-size: 18px;
	}
}

@media (min-width: 1380px) {
	.modal-stamp {
		font-size: 1.66vw;
		width: 5.5vw;
		height: 5.5vw;
	}
}

.has-layer-2 .layer-2 {
	background-color: transparent;
	-webkit-backdrop-filter: none;
	backdrop-filter: none;
}

.has-layer-2 .layer-1 .modal-container {
	opacity: 0;
	transform-style: preserve-3d;
	transform: translateY(80px) perspective(1000px) rotateX(-30deg);
}

.has-layer-2.has-layer-3 .layer-3 {
	background-color: transparent;
	-webkit-backdrop-filter: none;
	backdrop-filter: none;
}

.has-layer-2.has-layer-3 .layer-1 .modal-container,
.has-layer-2.has-layer-3 .layer-2 .modal-container {
	opacity: 0;
	transform-style: preserve-3d;
	transform: translateY(80px) perspective(1000px) rotateX(-30deg);
}

.has-layer-2.has-layer-3.has-layer-4 .layer-4 {
	background-color: transparent;
	-webkit-backdrop-filter: none;
	backdrop-filter: none;
}

.has-layer-2.has-layer-3.has-layer-4 .layer-1 .modal-container,
.has-layer-2.has-layer-3.has-layer-4 .layer-2 .modal-container,
.has-layer-2.has-layer-3.has-layer-4 .layer-3 .modal-container {
	opacity: 0;
	transform-style: preserve-3d;
	transform: translateY(80px) perspective(1000px) rotateX(-30deg);
}

.has-layer-2.has-layer-3.has-layer-4.has-layer-5 .layer-5 {
	background-color: transparent;
	-webkit-backdrop-filter: none;
	backdrop-filter: none;
}

.has-layer-2.has-layer-3.has-layer-4.has-layer-5 .layer-1 .modal-container,
.has-layer-2.has-layer-3.has-layer-4.has-layer-5 .layer-2 .modal-container,
.has-layer-2.has-layer-3.has-layer-4.has-layer-5 .layer-3 .modal-container,
.has-layer-2.has-layer-3.has-layer-4.has-layer-5 .layer-4 .modal-container {
	opacity: 0;
	transform-style: preserve-3d;
	transform: translateY(80px) perspective(1000px) rotateX(-30deg);
}

.flex-container {
	display: flex;
	align-items: baseline;
}


/*.btn*/
.btn-primary {
	color: #fff;
	background-color: #007bff;
	border-color: #007bff;
	border-radius: 5px;
	padding: 8px 17px;
	border: 1px none #aeaeae;
	font-size: 15px;
	margin-right:5px;
}
	.btn-primary:hover {
		color: #fff;
		background-color: #0069d9;
		border-color: #0062cc;
		cursor: pointer;
	}
.btn-dark {
	color: #212529;
	background-color: #ddd;
	border-color: #ddd;
	border-radius: 5px;
	padding: 8px 17px;
	border: 1px none #aeaeae;
	font-size: 15px;
	margin-right: 5px;
}
	.btn-dark:hover {
		color: #000;
		background-color: #BABABA;
		border-color: #BABABA;
		cursor: pointer;
	}

.form-item.show-help .help-word {
	opacity: 1;
	visibility: visible;
	pointer-events: auto;
}
.form-item.error .help-word {
	color: #0007ef;
}
.form-item .help-word {
	width: 100%;
	flex-shrink: 0;
	font-size: .75rem;
	padding-left: 30px;
	opacity: 0;
	transition: opacity .35s ease;
	visibility: hidden;
	pointer-events: none;
}
	.form-item .help-word .time {
		color: #F37402;
	}

.mark {
	list-style: "◎";
	text-indent: 8px;
	padding-left: 10px;
}
.cropContainer {
	position: relative;
	width:100%;
	height:400px;
	background: #333;
}

/* === range parameters === */
input[type="range"] {
	color: #F9A07B;
	--thumb-height: 1.125em;
	--track-height: 0.125em;
	--track-color: rgba(0, 0, 0, 0.2);
	--brightness-hover: 110%;
	--brightness-down: 80%;
	--clip-edges: 0.125em;
}
/* === range commons === */
input[type="range"] {
	font-size: 1rem;
	width: 100%;
	border: none;
	box-shadow: none !important;
	position: relative;
	background: #fff0;
	overflow: hidden;
}

input[type="range"]:active {
	cursor: grabbing;
}

input[type="range"]:disabled {
	filter: grayscale(1);
	opacity: 0.3;
	cursor: not-allowed;
}

/* === WebKit specific styles === */
input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	transition: all ease 100ms;
	height: var(--thumb-height);
}

input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
	position: relative;
}

input[type="range"]::-webkit-slider-thumb {
	--thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
	--clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
	--clip-bottom: calc(var(--thumb-height) - var(--clip-top));
	--clip-further: calc(100% + 1px);
	--box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
		100vmax currentColor;

	width: var(--thumb-width, var(--thumb-height));
	background: linear-gradient(currentColor 0 0) scroll no-repeat left center /
		50% calc(var(--track-height) + 1px);
	background-color: currentColor;
	box-shadow: var(--box-fill);
	border-radius: var(--thumb-width, var(--thumb-height));

	filter: brightness(100%);
	clip-path: polygon(
		100% -1px,
		var(--clip-edges) -1px,
		0 var(--clip-top),
		-100vmax var(--clip-top),
		-100vmax var(--clip-bottom),
		0 var(--clip-bottom),
		var(--clip-edges) 100%,
		var(--clip-further) var(--clip-further)
	);
}

input[type="range"]:hover::-webkit-slider-thumb {
	filter: brightness(var(--brightness-hover));
	cursor: grab;
}

input[type="range"]:active::-webkit-slider-thumb {
	filter: brightness(var(--brightness-down));
	cursor: grabbing;
}

input[type="range"]::-webkit-slider-runnable-track {
	background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center /
		100% calc(var(--track-height) + 1px);
}

input[type="range"]:disabled::-webkit-slider-thumb {
	cursor: not-allowed;
}

/* === Firefox specific styles === */
input[type="range"],
input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb {
	appearance: none;
	transition: all ease 100ms;
	height: var(--thumb-height);
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
	background: #fff0;
}

input[type="range"]::-moz-range-thumb {
	background: currentColor;
	border: 0;
	width: var(--thumb-width, var(--thumb-height));
	border-radius: var(--thumb-width, var(--thumb-height));
	cursor: grab;
}

input[type="range"]:active::-moz-range-thumb {
	cursor: grabbing;
}

input[type="range"]::-moz-range-track {
	width: 100%;
	background: var(--track-color);
}

input[type="range"]::-moz-range-progress {
	appearance: none;
	background: currentColor;
	transition-delay: 30ms;
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-progress {
	height: calc(var(--track-height) + 1px);
	border-radius: var(--track-height);
}

input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
	filter: brightness(100%);
}

input[type="range"]:hover::-moz-range-thumb,
input[type="range"]:hover::-moz-range-progress {
	filter: brightness(var(--brightness-hover));
}

input[type="range"]:active::-moz-range-thumb,
input[type="range"]:active::-moz-range-progress {
	filter: brightness(var(--brightness-down));
}

input[type="range"]:disabled::-moz-range-thumb {
	cursor: not-allowed;
}

.hide{
	display:none;
}

.react-datepicker__close-icon {
	display: none;
	pointer-events: none;
}

.react-datepicker__close-icon::after {
	display: none !important;
	pointer-events: none;
}
.d-inline {
	display:inline
}
.d-flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.d-inline-flex {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}
.d-inline-block {
	display:inline-block;
}
.align-items-center {
	-webkit-box-align: center !important;
	-ms-flex-align: center !important;
	align-items: center !important;
}
.flex-column {
	-webkit-box-orient: vertical !important;
	-webkit-box-direction: normal !important;
	-ms-flex-direction: column !important;
	flex-direction: column !important;
}
.d-block {
	display:block;
}
.px-2 {
	padding-left:1rem;
	padding-right:1rem;
}
.hoverArrow {
	cursor:pointer;
}
.arrowUp::after {
	content: "▲";
	display:block;
}
.arrowDown::after {
	content: "▼";
	display: block;
}
.arrowUp:hover::after, .arrowDown:hover::after {
	color: var(--secondry-color);
}
.sortable-list td{
	text-align:center;
	vertical-align:middle;
}
.d-inline-flex {
	display: -webkit-inline-box !important;
	display: -ms-inline-flexbox !important;
	display: inline-flex !important;
}

input.checkBoxize[type="radio"] {
	outline: 0;
	vertical-align: middle;
	-webkit-appearance: none;
	display: inline-block;
	position: relative;
	border-radius: 4px;
	cursor: pointer;
	width: 20px !important;
	height: 20px !important;
	border: 2px solid #ddd;
	margin: 0 5px;
	background-color: #FFFFFF;
	margin-top: 4px;
	margin-bottom: 4px;
	transition: transform 0.2s linear, box-shadow 0.2s linear;
}

input.checkBoxize[type="radio"]:checked {
	border: 1px solid #fff;
	background-color: var(--third-color);
	box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
	transform: scale(1.2);
}

input.checkBoxize[type="radio"]:checked:after {
	content: '\2714';
	font-size: 16px;
	position: absolute;
	top: -3px;
	left: 3px;
	color: #fff;
	background:unset;
}

	.react-datepicker__close-icon::after {
		display: none !important;
		pointer-events: none;
	}

.svrErr {
	border: 5px solid #0007ef
}
.text-danger {
	color:var(--danger-color);
}
.modify_fancy_Div {
	width:100%;
	overflow-x:auto;
}
.fancy_Item {
	display:inline-block;
	position:relative;
	padding:16px 7px 0px;
}
.fancy_Del_sapn {
	color: var(--danger-color);
	cursor:pointer;
	position:absolute;
	top:0;
	left:0;
}
.scoreSheet table {
	width: 100%;
	font-family: DFKai-sb;
	color:#000
}

.scoreSheet table tr td{
	margin:0;
	background:#fff;
	border:none;
}
	.scoreSheet figure.table {
		width: auto;
		margin-bottom: 0;
	}
.scoreSheet > figure > table > tbody > tr > td {
	
}
	.scoreSheet > figure > table > tbody > tr > td > figure > table > tbody >tr > td h3{
		font-weight: 500;
		font-size: 22px;
		text-align: left;
	}
.scoreSheet > figure > table > tbody > tr:nth-of-type(1) td {
	font-weight: normal;
	font-size: 28px;
	text-align: center;
}
.scoreSheet > figure > table > tbody > tr:nth-of-type(2) td figure:nth-of-type(1) {
	font-weight: normal;
	font-size: 22px;
	text-align: left;
	margin-top:0;
}
.scoreSheet > figure > table > tbody > tr:nth-of-type(2) td figure:nth-of-type(2) {
	margin-top:0;
}
.scoreSheet > figure > table > tbody > tr:nth-of-type(2) td figure:nth-of-type(2) td {
	font-family: "標楷體", "Arial", "Helvetica", "sans-serif";
	text-align: left;
	border: 1px solid #CCCCCC;
	padding-top: 5px;
	padding-right: 0px;
	padding-bottom: 5px;
	padding-left: 5px;
	font-size: 18px;
}
.scoreSheet > figure > table > tbody > tr:nth-of-type(2) td figure:nth-of-type(2) td:nth-of-type(1) {
	width:30%;
}
.scoreSheet > figure > table > tbody > tr:nth-of-type(2) td figure:nth-of-type(2) td:nth-of-type(2) {
	width: 70%;
}

.scoreSheet > figure > table > tbody > tr:nth-of-type(2) td figure:nth-of-type(3) {
	margin-top:0;
}
.scoreSheet > figure > table > tbody > tr:nth-of-type(2) td figure:nth-of-type(3) table tbody tr:nth-of-type(1) td {

}
.scoreSheet > figure > table > tbody > tr:nth-of-type(2) td figure:nth-of-type(3) table tbody tr:last-of-type td {
	font-size: 18px;
	text-align:center;
}
.scoreSheet > figure > table > tbody > tr:nth-of-type(2) td figure:nth-of-type(4) {
	margin-top: 0;
}
.scoreSheet > figure > table > tbody > tr:nth-of-type(2) td figure:nth-of-type(4) td{
	text-align:right;
}